import React from "react";
import {
  ScrollView, Text,
  ActivityIndicator,
  StyleSheet, View, TouchableOpacity
} from "react-native";
import { connect } from 'react-redux';
import { fetchOrderList, createRoutePlan} from '../reduxStore/actions/order/order';
import { fetchRiderListSuccess} from '../reduxStore/actions/rider/rider';
import {Header, OrderItem, RiderItem, SearchOrders} from "../components/index";
import { setShowAddRiderModalState } from './../reduxStore/actions/shared/shared';
import {getAuthToken, clearStoreData} from './../storageManager/index';
import {filterOrderist} from './../helper/index';

class Home extends React.Component {
  constructor(props) {
      super(props);
      this.state = {
      orders: [],
      loadAll : true
	  };
  }

  componentDidMount() {
    this.resetRiderList();
    this.fetchOrders();
  }

  resetRiderList(){
    var resultObj = {orderId : 0, riders : []}
    this.props.fetchRiderListSuccess(resultObj);
  }

  async fetchOrders() {
    var authToken = await getAuthToken();
    if(authToken){
      this.getOrders();
    } else {
      this.props.navigation.navigate('Login');
    }
  }

  // refreshOrderList(){
  //   this.resetRiderList();
  //   this.getOrders();
  // }

  getOrders() {
    this.props.fetchOrderList(response => {
      if(response.status == 401){
        this.props.navigation.navigate('Login');
        clearStoreData();
      }

     // stompSubscription();
    });
  }

  onSearch = (searchObj) =>{
    var searchedOrders = this.props.orders;

    this.setState({loadAll: false});

    if((!searchObj.listType && !searchObj.searchTxt) || (!searchObj.searchTxt && searchObj.listType && searchObj.listType == "all")){
      this.setState({loadAll: true});
    }

    if(searchObj.listType && searchObj.listType != 'all'){
      searchedOrders = filterOrderist(searchedOrders, "status", searchObj.listType);
    }

    /*isNaN(txt) will check if given value is numeric or not.
    true -> if it is string value
    false -> if it is numeric value */
    if(searchObj.searchTxt){
      var searchByArea = isNaN(searchObj.searchTxt);
      if(searchByArea){
        searchedOrders = filterOrderist(searchedOrders, "area", searchObj.searchTxt);
      } else {
        searchedOrders = filterOrderist(searchedOrders, "pin", searchObj.searchTxt);
      }
    }
    this.setState({orders: searchedOrders});
  }

  createRoutePlan(){
    this.props.createRoutePlan(response => {
      if(response.status == 401){
          this.props.navigation.navigate('Login');
          clearStoreData();
      }
  });
  }

  render() {
    return (
      <>
      {this.props.spinner ? (
        <ActivityIndicator
        style={[styles.centering]}
        color="#ff8179"
        size="large"
      />
          ) : <></>}
       <Header title="Home" /> 
     
      <ScrollView noSpacer={true} noScroll={false} style={styles.container}>

          {/* <View style = {{width : "95%", justifyContent : 'flex-end', alignItems : 'flex-end'}}>
            <View style = {{width : "22%", justifyContent : 'space-between', display : 'flex',  flexDirection : 'row'}}>
              <Link routeName="AddRider">Add Rider</Link>
              <Link routeName="DeliveryReport">Download Delivery Report</Link>
            </View>
          </View> */}
          <View style = {{width : "100%", height : 200}}>
              <SearchOrders search={this.onSearch}/>
          </View>

          <View style = {{display : 'flex', flexDirection : 'row'}}>
            { this.props.orders && this.props.orders.length != 0 &&
            <View style = {{flex : 3, display : 'flex', flexDirection : 'column', paddingLeft : 5, paddingRight : 5}}>
              <View>
                <Text style={[styles.titleText]}>Orders</Text>        
              </View>

              <View>
                <OrderItem 
                  orders={this.state.loadAll ? this.props.orders : this.state.orders} 
                  navigation = {this.props.navigation}/>
              </View>

              {/* <View style = {{justifyContent: "center", alignItems: "center", width : "80%", marginTop : 10, display : 'flex', flexDirection : 'row'}}>
                <TouchableOpacity onPress={() => this.refreshOrderList()} 
                style = {{alignItems : 'center', justifyContent : 'center'}}>
                  <View style={styles.btn}>
                    <Text style={styles.btnText}>Refresh</Text>
                  </View>
                </TouchableOpacity>
              </View> */}

               <View style = {{justifyContent: "center", alignItems: "center", marginTop : 10}}>
                <TouchableOpacity onPress={() => this.createRoutePlan()}>
                  <View style={styles.btn}>
                    <Text style={styles.btnText}>Optimize Route</Text>
                  </View>
                </TouchableOpacity>
              </View> 
             
            </View>
            }
            { this.props.riders && this.props.riders.length != 0 && this.props.orders && this.props.orders.length != 0 &&
            <View style = {{ flex : 2, display : 'flex', flexDirection : 'column'}}>
              <View>
                <Text style={styles.titleText}>Available Riders</Text>
              </View>

              <View>
                 {/* Reused this component to list all riders in riders screen */}
                <RiderItem riders={this.props.riders} listForOrder = {true} navigation = {this.props.navigation}/>
              </View>
            </View>
            }
          </View>
      </ScrollView>
      </>
    );
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: "whitesmoke",
  },
  centering: {
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    height: '100vh'
  },
  titleText: {
    fontSize: 15,
    textTransform: "capitalize",
    color: "#147669",
    lineHeight : 30
  },
  btn: { width: 110, height: 30, backgroundColor: '#27b45c',  borderRadius: 5,  alignItems: "center", justifyContent: "center"},
  btnText: { textAlign: 'center', color: '#fff' }
});

const mapStateToProps = (state) => ({
  orders: state.order.orders,
  riders: state.rider.riders,
  spinner : state.shared.loading
});

const mapDispatchToProps = dispatch => {
    return {
      fetchOrderList: (cb) => dispatch(fetchOrderList(cb)),
      fetchRiderListSuccess: (payloadObj) => dispatch(fetchRiderListSuccess(payloadObj)),
      setShowAddRiderModalState: (val) => dispatch(setShowAddRiderModalState(val)),
      createRoutePlan: (cb) => dispatch(createRoutePlan(cb)) 
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
