import React from 'react';
import PropTypes from 'prop-types';
import {
  Dimensions,
  StyleSheet,
  ScrollView,
  View,
  Text,
} from 'react-native';
const window = Dimensions.get('window');

const styles = StyleSheet.create({
   container: {
    flex: 1,
    backgroundColor: "white",
  },
  menu: {
    flex: 1,
    width:window.width/4,
    height: 10,
    //window.height,
    backgroundColor: 'white',
    padding: 20,
    zIndex:1
  },
  avatarContainer: {
    marginBottom: 20,
  },
  avatar: {
    width: 48,
    height: 48,
    borderRadius: 24,
    flex: 1,
  },
  name: {
    position: 'absolute',
    left: 0,
    fontWeight: 'bold',
    paddingHorizontal: 28,
    flex:1,
    top:20,
    fontSize: 18,
    marginLeft: 12,
  },
  item: {
    paddingTop: 25,
    top:20,
    lineHeight:10,
    //color:'white',
    fontSize: 18,
    marginLeft: 12,
    fontWeight: "normal",
    paddingHorizontal: 28,
    //fontWeight:'bold'
  },
  itemStyle:{
  //  lineHeight:10,
  /* activeTintcolor: "white",
  inactiveTintColor: "#000",
  activeBackgroundColor: "transparent",
 */
    flex:1,
    top:20,
  }
});

export default function Menu({ onItemSelected }) {
  
  return (
    <View
    style={styles.container}
    forceInset={{ top: 'always', horizontal: 'never' }}
  >
    <ScrollView scrollsToTop={false} style={styles.menu}>
      <View style={styles.avatarContainer}>
        <Text style={styles.name}>SHARA</Text>
      </View>
      <View style={styles.itemStyle}>
        <Text
          onPress={() => onItemSelected('Home')}
          style={styles.item}
        >
          Home
        </Text>
        <Text
          onPress={() => onItemSelected('AddRider')}
          style={styles.item}
        > 
          Add Rider
        </Text>
        <Text
          onPress={() => onItemSelected('Riders')}
          style={styles.item}
        >
          Riders
        </Text>
        <Text
          onPress={() => onItemSelected('DeliveryReport')}
          style={styles.item}
        >
          Delivery Report
        </Text>
      </View>
    </ScrollView>
    </View>
  );
}

Menu.propTypes = {
  onItemSelected: PropTypes.func.isRequired,
};
/*
export default class Menu extends React.Component {
  
  constructor( props ) {
    super( props );
    const routes=[]
     this.state = {
      //Add a state to track what current selected Item
      selectedItem: routes[ 0 ]
    };
  }

  static propTypes = {
    onItemSelected: PropTypes.func.isRequired,
  };

  render() { 
    const routes=[
      "Home", 
      "Add Rider",
      "Download Report",
    ]; 
    return (
      
    <ScrollView style={ styles.menu }>
      //the route is the menu items with component
      { routes.map( (item) => {
          return ( <Text
                          key={ item }
                          onPress={ () => {
                                      this.props.onItemSelected( item )
                                      //Change the current selected Item
                                      this.setState( {
                                        selectedItem: item
                                      } )
                                    } }
                          style={ [ styles.item, 
                                    { 
                                      backgroundColor: this.state.selectedItem == item ? 
                                      'red' : 'white' 
                                    } 
                                  ] }>
                     { item.name }
                     </Text>
          )
        } ) }
    </ScrollView>
    );
  }
}*/
