import {SET_LOADING_STATE, SET_SHOW_ADD_RIDER_MODAL_STATE} from '../../actions/shared/sharedActionTypes';

  const initialState = {
      loading : false,
      showAddRiderModal : false
    }
    
    const sharedReducer = (state = initialState, action) => {
      switch (action.type) {
        case SET_LOADING_STATE:
          return {
            ...state,
            loading : action.payload
          }
        case SET_SHOW_ADD_RIDER_MODAL_STATE:
          return {
            ...state,
            showAddRiderModal : action.payload
          }
        default: return state
      }
    }
    
    export default sharedReducer;
    