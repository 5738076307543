import AsyncStorage from '@react-native-async-storage/async-storage';

export const getAuthToken = async() => {
    var authToken = await AsyncStorage.getItem('AuthToken');
    return authToken;
}

export const storeAuthToken = async (authToken) => {
    try {
      await AsyncStorage.setItem('AuthToken', authToken);
      console.log('Saved AuthToken');
    } catch (error) {
      console.log('not saved AuthToken');
    }
};

export const getFromFilter = async() => {
  var fromFilterVal = await AsyncStorage.getItem('userFromFilter');
  return fromFilterVal;
}

export const storeFromFilter = async (fromFilterVal) => {
  try {
    await AsyncStorage.setItem('userFromFilter', fromFilterVal);
    console.log('Saved fromFilterVal');
  } catch (error) {
    console.log('not saved fromFilterVal');
  }
};

export const clearStoreData = async () => {
    try {
        const keys = await AsyncStorage.getAllKeys();
        console.log('keys -- ', keys);
        await AsyncStorage.multiRemove(keys);
        console.log('Cleared store');
    } catch (error) {
        console.error('Error clearing app data.');
    }
  }
  