import Axios from 'axios'

const axios = Axios.create({
    baseURL: '',
    //baseURL: 'http://localhost:6082',
    keepAlive: true,
   // withCredentials: true
    // headers: {
    //     'x-auth': localStorage.getItem('token')
    // }
})

export default axios;
