import {FETCH_ORDERS, FETCH_ORDER} from '../../actions/order/orderActionTypes';

    const initialState = {
      orders : [],
    }
    
    const orderReducer = (state = initialState, action) => {
      switch (action.type) {
        case FETCH_ORDERS:
          return {
            orders : action.payload
          }
        case FETCH_ORDER:
          var ordInd = getOrderIndex(state.orders, action.payload);

          var changedValues = [];
          if(ordInd != -1){
            changedValues = [...state.orders];
            if(action.payload.status != 'DLD'){
              changedValues[ordInd].status = action.payload.status;
            } else {
              changedValues.splice(ordInd, 1);
            }
           
          } else {
            changedValues = [...state.orders, action.payload]
          }
          return {
            orders : changedValues
          }
        default: return state
      }
    }

    const getOrderIndex = (orderList, orderItem) => {
      var orderInd = '-1';
      orderList.map((order, i) => {
        if(orderItem.id == order.id){
          orderInd = i;
        }
      });

      return orderInd;
    }
    
    export default orderReducer;
    