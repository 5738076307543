import {createStackNavigator} from 'react-navigation-stack';
import {createAppContainer} from 'react-navigation';
import Login from "../screens/Login";
import Home from "../screens/Home";
import SideMenus from "../screens/SideMenu";
import InitScreen from "../screens/InitScreen";
import DeliveryReport from "../screens/DeliveryReport";
import AddRider from "../screens/AddRider";
import {Platform} from "react-native";
import TrackMap from '../screens/TrackMap';

const isWeb = Platform.OS === 'web';

const Stack = createStackNavigator(
    {
        InitScreen : InitScreen,
        SideMenus: SideMenus,
        Login: Login,
        TrackMap: {screen : TrackMap, path : 'TrackMap/:riderId'},
        //Home: Home,
        //AddRider: AddRider,
        //DeliveryReport: DeliveryReport
    },
    {
        /*to remove default nav bar */      
        defaultNavigationOptions: {
          header: null
        },
    },
    // {
    //     navigationOptions: {
    //         headerTintColor: '#fff',
    //         headerStyle: {
    //             backgroundColor: '#000',
    //         },
    //     },
    // }
    {
   	  initialRouteName: 'InitScreen',
    }
);
//  isWeb ? createBrowserApp(Stack):
const ScreenContainer = createAppContainer(Stack);

export default ScreenContainer;
