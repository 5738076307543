import React from "react";
import {StyleSheet, View, Text, TouchableOpacity, TextInput, ScrollView} from 'react-native';
//import {Dropdown } from 'react-native-dropdown';
//import {Dropdown } from 'react-native-material-dropdown';
//import DropdownMenu from 'react-native-dropdown-menu';
import MultiSelect from 'react-native-multiple-select';
import { connect } from 'react-redux';
import { ORDER_LIST_TYPES } from './../constants/shared';

class SearchOrders extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            selectedListType: [],
            searchTxt: ''
        })
    }

    componentDidMount(){
        this.clearInputs();
    }

    onSelectedListTypeChange = selectedListType => {
        this.setState({ selectedListType });
    };

    search(isRefresh){
       var searchObj = {};

       if(!isRefresh){
            searchObj["listType"] = this.state.selectedListType[0];
            searchObj["searchTxt"] = this.state.searchTxt;
       }
       
       this.props.search(searchObj);
    }

    clearInputs(){
        this.setState({searchTxt: ""});
        this.setState({selectedListType: []});
        this.searchTxt.clear();

       this.search(true);
    }

    renderDropDown = () => {
        return (  
            <></>
            // <Dropdown
            //     label='Label Text'  data={ORDER_LIST_TYPES}
            //     containerStyle={styles.dropdownStyle}
            //     onChangeText={
            //         (dropdownitemvalue) => {
            //         this.setState({searchTxt: dropdownitemvalue });
            //         }
            //     }
            // ></Dropdown>
            
            )
      }

	render() {
		return (
            <>
                 <View style = {styles.parentView}>
                        <View style={styles.inputContainer}>
                            <View style = {{width : "15%", marginLeft: 20}}>
                                <View style = {styles.labelContainer}>
                                        <Text>Status</Text>
                                </View>
                                <View style={styles.selectItemContainer}>
                                {/* <DropdownMenu
                                    style={{flex: 0.5}}
                                    bgColor={'grey'}
                                    tintColor={'#000000'}
                                    activityTintColor={'red'}
                                    handler={(selection, row) => this.setState({searchTxt: ORDER_LIST_TYPES[selection][row]})}
                                    data={ORDER_LIST_TYPES}
                                    >
                                    </DropdownMenu>  */}
                                 {/* {this.renderDropDown()} */}
                                    <ScrollView>
                                        <MultiSelect
                                                hideTags
                                                iconSearch = {false}
                                                items={ORDER_LIST_TYPES}
                                                single={true}
                                                uniqueKey={"value"}
                                                ref={(component) => { this.multiSelect = component }}
                                                onSelectedItemsChange={this.onSelectedListTypeChange}
                                                selectedItems={this.state.selectedListType}
                                                selectText="Select"
                                                searchInputPlaceholderText="Search..."
                                                onChangeInput={ (text)=> console.log(text)}
                                                tagRemoveIconColor="#CCC"
                                                tagBorderColor="#CCC"
                                                tagTextColor="#CCC"
                                                selectedItemTextColor="#CCC"
                                                selectedItemIconColor="#CCC"
                                                itemTextColor="#000"
                                                displayKey="name"
                                                searchInputStyle={{ color: '#CCC' }}
                                                submitButtonColor="#CCC"
                                                submitButtonText="Select"
                                                styleItemsContainer ={{marginLeft : 30, width : "100%"}}
                                        />
                                    </ScrollView>
                                </View>
                            </View>
                            <View style = {{width : "15%", marginLeft: 20}}>
                                <View style = {styles.labelContainer}>
                                        <Text>Pin / Area</Text>
                                </View>
                                <TextInput
                                        style={styles.inputText}
                                        placeholder="Pin / Area"
                                        ref={searchTxtInput => { this.searchTxt = searchTxtInput }}
                                        onChangeText={text => this.setState({searchTxt : text})}
                                        placeholderTextColor="#949494"
                                />
                                
                            </View>
                            
                        <View style = {{width : '10%', marginTop : 20, marginLeft: 20}}>
                            <TouchableOpacity style={styles.containerButton} onPress={() => this.search()}>
                            <Text style={styles.caption}>Search</Text>
                            </TouchableOpacity>
                        </View>
                        <View style = {{width : '10%', marginTop : 20}}>
                            <TouchableOpacity style={styles.containerButton} onPress={() => this.clearInputs()}>
                            <Text style={styles.caption}>Clear</Text>
                            </TouchableOpacity>
                        </View>
                        </View>
                    </View>
            </>
		);
	}

}

var styles = StyleSheet.create({
    parentView : {
        display : 'flex',
        flexDirection : 'column',
        width : '97%',
        height : "90%",
        marginTop: 20,
        marginLeft: 20,
        borderColor: '#27b45c',
        borderWidth: 1,
        borderRadius: 10
    },
    labelContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 20
    },
    inputContainer:{
        flex: 1,
        flexDirection: 'row',
        width: '100%',
        marginTop: 30,
        //justifyContent : 'space-between',
        justifyContent : 'flex-start'
    },
    footerBlock: {
        width: '98%',
        height: "30%",
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        display : 'flex',
        flexDirection : 'row'
    },
    inputText: {
        height: 30,
        backgroundColor: '#eeeeee',
        width: '100%',
        borderRadius: 5,
        paddingLeft: 15,
        alignItems: "center",
        justifyContent: "center",
    },
    containerButton: {
        width: '90%',
        height: 35,
        backgroundColor: "#27b45c",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10
    },
    caption: {
        color: "#fff",
        fontSize: 17,
       // fontWeight: "bold",
        fontFamily: "roboto-500"
    },
    selectItemContainer: {
       // width: '20%',
        height: '70%',
        backgroundColor: '#FFFFFF',
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
    }
});

const mapStateToProps = (state) => ({
    showAddRiderModal : state.shared.showAddRiderModal
});
  
const mapDispatchToProps = dispatch => {
    return {
    }
}
  
export default connect(
mapStateToProps,
mapDispatchToProps
)(SearchOrders);
  
