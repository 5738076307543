import {combineReducers} from 'redux';
//import storage from '@react-native-community/async-storage';
import orderReducer from './../reducers/order/orderReducer';
import riderReducer from './../reducers/rider/riderReducer';
import sharedReducer from './../reducers/shared/sharedReducer';
const appReducer = combineReducers({
  order: orderReducer,
  rider: riderReducer,
  shared : sharedReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
