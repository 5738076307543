import React from "react";
import {
  StyleSheet,
  View
} from 'react-native';
import Home from "../screens/Home";
import Riders from "../screens/Riders";
import AddRider from "../screens/AddRider";
import DeliveryReport from "../screens/DeliveryReport";

export default class SideView extends React.Component {

	render() {
		return (
			<View style={styles.container}>  
          {this.props.selectedItem =='Home'  ?
          <Home navigation = {this.props.navigation}/>
          :null}
          
          {this.props.selectedItem =='AddRider' ?
          <AddRider navigation = {this.props.navigation}/>
          :null}
          
          {this.props.selectedItem =='DeliveryReport' ?
          <DeliveryReport navigation = {this.props.navigation}/>
          :null}

          {this.props.selectedItem =='Riders'  ?
          <Riders navigation = {this.props.navigation}/>
          :null}
      </View>  
		);
	}

}

const styles = StyleSheet.create({
    container: {
      flex: 1,
      backgroundColor: '#F5FCFF',
    }
  });
