import React from "react";
import {StyleSheet, View, Text, TouchableOpacity, TextInput, ScrollView} from 'react-native';
import MultiSelect from 'react-native-multiple-select';
import { connect } from 'react-redux';
import {Header} from "../components/index";
import { setShowAddRiderModalState } from './../reduxStore/actions/shared/shared';
import { addRider } from './../reduxStore/actions/rider/rider';
import {getAuthToken, clearStoreData} from './../storageManager/index';
import { ROLE_LIST } from './../constants/shared';
class AddRider extends React.Component {

    constructor(props) {
        super(props);
        this.state = ({
            name: '',
            mobileNo: '',
            selectedRole: [],
            address: '',
            post: '',
            pin: ''
        })
    }

    componentDidMount(){
        this.checkForAuthToken();
        this.clearInputs();
        console.log(ROLE_LIST);
    }

    async checkForAuthToken(){
        var authToken = await getAuthToken();
        if(!authToken){
            this.props.navigation.navigate('Login');
            clearStoreData();
        }
    }

    onSelectedRoleChange = selectedRole => {
        this.setState({ selectedRole });
    };

    addRider(){
        var reqObj = {name : this.state.name, mobileNo : this.state.mobileNo, 
            role : this.state.selectedRole[0], address : this.state.address, post : this.state.post, pin : this.state.pin};
        this.props.addRider(reqObj, response => {
            var message = '';
            if(response.successMessages){
                this.clearInputs();
                message = response.successMessages[0];
                if(response.successMessages.length > 1){
                	message = message + response.successMessages[1];
                }
            }
      
            if(response.errorMessages){
              message = response.errorMessages[0];
            }
      
            if(message){
              alert(message);
            }
      
            if(response.status == 401){
              this.props.navigation.navigate('Login');
              clearStoreData();
            }
        });
    }

    clearInputs(){
        this.setState({name: ""});
        this.setState({mobileNo: ""});
        this.setState({selectedRole: []});
        this.setState({address: ""});
        this.setState({post: ""}); 
        this.setState({pin: ""});
        this.name.clear();
        this.mobileNo.clear();
       // this.multiSelect.clear();
        this.address.clear();
        this.post.clear();
        this.pin.clear();
    }

	render() {
		return (
            <>
                <Header title="Add Rider" />
               
                {/* <View style = {{width : "95%", justifyContent : 'flex-start', alignItems : 'flex-start'}}>
                    <Link routeName="Home"> Back to Home</Link>
                </View> */}
                <ScrollView noSpacer={true} noScroll={false} style = {{height : '100%'}}>
                <View style = {{ width: '98%',  height: '100%',  marginLeft : 12, marginTop : 20, borderColor: '#27b45c', borderWidth: 1, borderRadius: 10}}>
                
                    <View style = {styles.parentView}>
                        <View style={styles.inputContainer}>
                            <View style = {{width : "15%"}}>
                                <View style = {styles.labelContainer}>
                                        <Text>Name</Text>
                                </View>
                                <TextInput
                                        style={styles.inputText}
                                        placeholder="Name"
                                        ref={nameInput => { this.name = nameInput }}
                                        onChangeText={text => this.setState({name : text})}
                                        placeholderTextColor="#949494"
                                />
                            </View>
                            <View style = {{width : "15%"}}>
                                <View style = {styles.labelContainer}>
                                        <Text>Mobile No.</Text>
                                </View>
                                <TextInput
                                        style={styles.inputText}
                                        placeholder="Mobile No."
                                        keyboardType = 'numeric'
                                        maxLength={12}
                                        ref={mobileNoInput => { this.mobileNo = mobileNoInput }}
                                        onChangeText={text => this.setState({mobileNo: '+' + text})}
                                        placeholderTextColor="#949494"
                                /> 
                            </View>
                            <View style = {{width : "15%"}}>
                                <View style = {styles.labelContainer}>
                                        <Text>Role</Text>
                                </View>
                                <View style={styles.selectItemContainer}>
                                    <ScrollView>
                                        <MultiSelect
                                                hideTags
                                                iconSearch = {false}
                                                items={ROLE_LIST}
                                                single={true}
                                                uniqueKey={"value"}
                                                ref={(component) => { this.multiSelect = component }}
                                                onSelectedItemsChange={this.onSelectedRoleChange}
                                                selectedItems={this.state.selectedRole}
                                                selectText="Select Role"
                                                searchInputPlaceholderText="Search Role..."
                                                onChangeInput={ (text)=> console.log(text)}
                                                tagRemoveIconColor="#CCC"
                                                tagBorderColor="#CCC"
                                                tagTextColor="#CCC"
                                                selectedItemTextColor="#CCC"
                                                selectedItemIconColor="#CCC"
                                                itemTextColor="#000"
                                                displayKey="name"
                                                searchInputStyle={{ color: '#CCC' }}
                                                submitButtonColor="#CCC"
                                                submitButtonText="Select"
                                                styleItemsContainer ={{marginLeft : 30, width : "100%"}}
                                        />
                                    </ScrollView>
                                </View>
                            </View>
                            <View style = {{width : "20%"}}>
                                <View style = {styles.labelContainer}>
                                        <Text>Address</Text>
                                </View>
                                <TextInput
                                        style={[styles.inputText, {height : 100, width: 250, paddingTop : 10}]}
                                        placeholder="Address"
                                        multiline={true}
                                        ref={addressInput => { this.address = addressInput }}
                                        onChangeText={text => this.setState({address: text})}
                                        placeholderTextColor="#949494"
                                />
                            </View>
                            <View style = {{width : "10%"}}>
                                <View style = {styles.labelContainer}>
                                        <Text>Post</Text>
                                </View>
                                <TextInput
                                        style={styles.inputText}
                                        placeholder="Post"
                                        ref={postInput => { this.post = postInput }}
                                        onChangeText={text => this.setState({post : text})}
                                        placeholderTextColor="#949494"
                                /> 
                            </View>
                            <View style = {{width : "10%"}}>
                                <View style = {styles.labelContainer}>
                                        <Text>Pin</Text>
                                </View>
                                <TextInput
                                        style={styles.inputText}
                                        placeholder="Pin"
                                        keyboardType = 'numeric'
                                        maxLength={6}
                                        ref={pinInput => { this.pin = pinInput }}
                                        onChangeText={text => this.setState({pin : text})}
                                        placeholderTextColor="#949494"
                                />
                            </View>
                        </View>
                    </View>
                    <View style={styles.footerBlock}>
                        <View style = {{width : '10%'}}>
                            <TouchableOpacity style={styles.containerButton} onPress={() => this.clearInputs()}>
                            <Text style={styles.caption}>Clear</Text>
                            </TouchableOpacity>
                        </View>
                        <View style = {{width : '10%'}}>
                            <TouchableOpacity style={styles.containerButton} onPress={() => this.addRider()}>
                            <Text style={styles.caption}>Add</Text>
                            </TouchableOpacity>
                        </View>
                    </View>
                    
                </View>
                </ScrollView>
            </>
		);
	}

}

var styles = StyleSheet.create({
    parentView : {
        display : 'flex',
        flexDirection : 'column',
        width : '97%',
        height : "50%",
        marginTop: 20,
        marginLeft: 20
    },
    labelContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        height: 20
    },
    inputContainer:{
        flex: 3,
        flexDirection: 'row',
        width: '100%',
        justifyContent : 'space-between',
    },
    footerBlock: {
        width: '100%',
        height: "30%",
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        display : 'flex',
        flexDirection : 'row'
    },
    inputText: {
        height: 30,
        backgroundColor: '#eeeeee',
        width: '100%',
        borderRadius: 5,
        paddingLeft: 15,
        alignItems: "center",
        justifyContent: "center",
    },
    containerButton: {
        width: '90%',
        height: 35,
        backgroundColor: "#27b45c",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 10
    },
    caption: {
        color: "#fff",
        fontSize: 17,
       // fontWeight: "bold",
        fontFamily: "roboto-500"
    },
    selectItemContainer: {
       // width: '20%',
        height: '70%',
        backgroundColor: '#FFFFFF',
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
    }
});

const mapStateToProps = (state) => ({
    showAddRiderModal : state.shared.showAddRiderModal
});
  
const mapDispatchToProps = dispatch => {
    return {
        setShowAddRiderModalState: (val) => dispatch(setShowAddRiderModalState(val)),
        addRider: (reqObj, cb) => dispatch(addRider(reqObj, cb))
    }
}
  
export default connect(
mapStateToProps,
mapDispatchToProps
)(AddRider);
  
