import axios from '../../../config/axios/axios';
import env from "../../../environment/env.js";
import {storeAuthToken, storeFromFilter} from './../../../storageManager/index';
import {setLoadingState} from './../shared/shared';

const basePath = env.DEV_API;
const orgCode = env.ORG_CODE;

export const userLogin = (userData, cb) => {
    return (dispatch) => {
      var url = basePath + '/api/login?org=' + orgCode + '&r8='
        dispatch(setLoadingState(true));
        axios
        .post(url, userData)
        .then((response) => {
          console.log('login success');

          dispatch(setLoadingState(false));

          if(response && response.data){
            if(response.data.authToken){
              storeAuthToken(response.data.authToken);
            }

            if(response.data.user){
              storeFromFilter(response.data.user.fromFilter);
            }
          }

          if (typeof cb === 'function') {
            cb(response);
          }
		  
        })
        .catch((error) => {
          console.log('error', JSON.stringify(error));
          dispatch(setLoadingState(false));
	        if (typeof cb === 'function') {
	            cb(error);
	        }
        });
    };
  };