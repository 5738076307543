import React from "react";
import {
  ScrollView, Text,
  ActivityIndicator,
  StyleSheet, View
} from "react-native";
import { connect } from 'react-redux';
import {Header, RiderItem} from "../components/index";
import {getAuthToken} from './../storageManager/index';
import {getAllRiders} from '../reduxStore/actions/rider/rider';

class Riders extends React.Component {
  constructor(props) {
      super(props);
  }

  componentDidMount() {
    this.getAllRiderList();
  }

  async getAllRiderList(){
    var authToken = await getAuthToken();
    if(authToken){
        this.props.getAllRiders(response => {
            if(response.status == 401){
                this.props.navigation.navigate('Login');
                clearStoreData();
            }
        });
    } else {
        this.props.navigation.navigate('Login');
    }
    
  }

  render() {
    return (
      <>
      {this.props.spinner ? (
        <ActivityIndicator
        style={[styles.centering]}
        color="#ff8179"
        size="large"
      />
          ) : <></>}
       <Header title="Home" /> 
     
      <ScrollView noSpacer={true} noScroll={false} style={styles.container}>
          <View style = {{width : "100%", display : 'flex', flexDirection : 'column'}}>
            { this.props.riders && this.props.riders.length != 0 &&
            <>
              <View style = {{justifyContent: "center", alignItems: "center"}}>
                <Text style={styles.titleText}>Available Riders</Text>
              </View>

              <View style = {{justifyContent: "center", width : "100%"}}>
                 {/* Reused this component to list riders based on selected order */}
                <RiderItem riders={this.props.riders} listForOrder = {false} navigation = {this.props.navigation}/>
              </View>
              </>
            }
          </View>
      </ScrollView>
      </>
    );
  }
}

var styles = StyleSheet.create({
  container: {
    backgroundColor: "whitesmoke",
  },
  centering: {
    alignItems: "center",
    justifyContent: "center",
    padding: 8,
    height: '100vh'
  },
  titleText: {
    fontSize: 15,
    textTransform: "capitalize",
    color: "#147669",
    lineHeight : 30
  },
  btn: { width: 70, height: 30, backgroundColor: '#27b45c',  borderRadius: 5,  alignItems: "center", justifyContent: "center"},
  btnText: { textAlign: 'center', color: '#fff' }
});

const mapStateToProps = (state) => ({
  riders : state.rider.allRiders,
  spinner : state.shared.loading
});

const mapDispatchToProps = dispatch => {
    return {
      getAllRiders: (cb) => dispatch(getAllRiders(cb)) 
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Riders);
