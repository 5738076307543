import {FETCH_RIDERS, FETCH_ALL_RIDERS} from '../../actions/rider/riderActionTypes';

    const initialState = {
      selectedOrder : {},
      riders : [],
      allRiders: []
    }
    
    const riderReducer = (state = initialState, action) => {
      switch (action.type) {
        case FETCH_RIDERS:
          return {
            ...state,
            riders : action.payload.riders,
            selectedOrder : action.payload.order
          }
        case FETCH_ALL_RIDERS:
          return {
            ...state,
            allRiders : action.payload,
          }
        default: return state
      }
    }
    
    export default riderReducer;
    