import axios from '../../../config/axios/axios';
import env from "../../../environment/env.js";
import {FETCH_ORDERS, FETCH_ORDER} from './orderActionTypes';
import {setLoadingState} from './../shared/shared';
import {getAuthToken} from './../../../storageManager/index';

const basePath = env.DEV_API;

export const fetchOrderList = (cb) => {
    return async (dispatch) => {
     var payloadObj = [];
     dispatch(fetchOrderListSuccess(payloadObj));
     var authToken = await getAuthToken();
     if(authToken){
      dispatch(setLoadingState(true));
      var url = basePath + '/api/v1/orders';
      axios
      .get(url, { headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ authToken,
         }})
      .then((response) => {
        console.log('fetchOrderList success');

        dispatch(setLoadingState(false));

        if(response){
          dispatch(fetchOrderListSuccess(response.data.deliveryRequests));
        }

        if (typeof cb === 'function') {
          cb(response);
        }
        
      })
      .catch((error) => {
        console.log('error', JSON.stringify(error));
        dispatch(setLoadingState(false));
        if (typeof cb === 'function') {
            cb({status : 401});
        } 
      });
     }
    };
  };

  export const createRoutePlan = (cb) => {
    return async (dispatch) => {
     var authToken = await getAuthToken();
     if(authToken){
      dispatch(setLoadingState(true));
      var url = basePath + '/api/v1/routePlan';
      axios
      .get(url, { headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+ authToken,
         }})
      .then((response) => {
        console.log('route plan creation');

        dispatch(setLoadingState(false));

        if (typeof cb === 'function') {
          cb(response);
        }
        
      })
      .catch((error) => {
        console.log('error', JSON.stringify(error));
        dispatch(setLoadingState(false));
        if (typeof cb === 'function') {
            cb({status : 401});
        } 
      });
     }
    };
  };

  export function orderDetails(ordDetails, cb) {    
    return async(dispatch) => { 
      dispatch({ type: 'RESET_LOADING_STATE', payload: true });
      var authToken = await getAuthToken();

      if(authToken){
        dispatch(setLoadingState(true));
        var url = basePath + '/api/v1/order/cancel';
        axios
        .put(url,ordDetails,{headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer '+authToken,
        }})
        .then((response) => {

          dispatch(setLoadingState(false));

          if (typeof cb === 'function') {
            cb(response.data);
          }

        })
        .catch((error) => {
          console.log('error', JSON.stringify(error));
          dispatch(setLoadingState(false));
          if (typeof cb === 'function') {
              cb({status : 401});
          }
        });
     }
    } 
  }
  
  export const fetchOrderListSuccess = (val) => {
    return {type: FETCH_ORDERS, payload: val };
  };

  export const fetchOrder = (val) => {
    return {type: FETCH_ORDER, payload: val };
  };