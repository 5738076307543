import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Image,
  TouchableOpacity,
  Dimensions
} from 'react-native';
import SideMenu from 'react-native-side-menu';
const image = require('./../../assets/menu.png');
import {Menu} from "../components/index";
import { connect } from 'react-redux';
import {stompRegister, stompDisconnect} from '../reduxStore/actions/stompClient/stompClientAction';
import {SideView} from "../components/index";

const window = Dimensions.get('window');

class SideMenus extends Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      selectedItem: 'Home',
    };
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }

  updateMenuState(isOpen) {
    this.setState({ isOpen });
  }

  onMenuItemSelected = (item) =>{
    this.setState({
      isOpen: false,
      selectedItem: item,
    });
  }

  componentDidMount() {

    this.props.stompRegister();
  }

  componentWillUnmount(){
    stompDisconnect();
  }


render() {
    const menu = <Menu onItemSelected={this.onMenuItemSelected} />;
    return (
      <SideMenu
        menu={menu}
        openMenuOffset={window.width/5}
        isOpen={this.state.isOpen}
        hiddenMenuOffset = {0}
        onChange={isOpen => this.updateMenuState(isOpen)}
      >
        <SideView selectedItem = {this.state.selectedItem} navigation = {this.props.navigation}/>
        <TouchableOpacity
          onPress={this.toggle}
          style={styles.button}
        >
          <Image
            source={image}
            style={{ width: 32, height: 32 }}
          />
        </TouchableOpacity>    
    </SideMenu>  
    );
    }
}

const styles = StyleSheet.create({
  button: {
    position: 'absolute',
    padding: 10,
  },
  caption: {
    fontSize: 20,
    fontWeight: 'bold',
    alignItems: 'center',
  },
  container: {
    flex: 1,
    backgroundColor: '#F5FCFF',
  },
});

const mapDispatchToProps = dispatch => {
    return {
      stompRegister: () => dispatch(stompRegister())
    }
}

export default connect(
  null,
  mapDispatchToProps
)(SideMenus);
