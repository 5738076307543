import axios from '../../../config/axios/axios';
import env from "../../../environment/env.js";
import {FETCH_RIDERS, FETCH_ALL_RIDERS} from './riderActionTypes';
import {setLoadingState} from './../shared/shared';
import {getAuthToken} from './../../../storageManager/index';
import {getNearestRider} from './../../../helper/index';

const basePath = env.DEV_API;
const orgCode = env.ORG_CODE;

export const fetchRiderList = (orderData, selectedOrder,cb) => {
    return async (dispatch) => {
      var authToken = await getAuthToken();
      if(authToken){
        dispatch(setLoadingState(true));
        var url = basePath + '/api/v1/riders?lat=' + orderData.frmLat + '&lng=' + orderData.frmLong + '&ordId=' + orderData.ordId
        axios
        .get(url, { headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ authToken,
          }})
        .then((response) => {
        console.log('fetchRiderList success');

        dispatch(setLoadingState(false));

        if(response && response.data && response.data.riders){
          let nearestRiders = getNearestRider(response.data.riders);
         
          if(nearestRiders != null && nearestRiders.length > 0){
            var riderList = []    
            for(var i=0; i <  nearestRiders.length;i++){
              if(nearestRiders[i].riderId === selectedOrder.riderId){
                riderList.splice(0,0,nearestRiders[i])
              }
              else{
                riderList.push(nearestRiders[i])
              }
            }
            var resultObj = {order : orderData, riders : riderList}
            dispatch(fetchRiderListSuccess(resultObj));
          }
        }

        if (typeof cb === 'function') {
          cb(response.data);
        }
       
        })
        .catch((error) => {
          console.log('error', JSON.stringify(error));
          dispatch(setLoadingState(false));
          if (typeof cb === 'function') {
              cb({status : 401});
          }
        });
     }
    };
  };

  export const getAllRiders = (cb) => {
    return async (dispatch) => {
      var authToken = await getAuthToken();
      if(authToken){
        dispatch(setLoadingState(true));
        var url = basePath + '/api/v1/riders/all';
        axios
        .get(url, { headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ authToken,
          }})
        .then((response) => {
        console.log('fetchAllRiders success');

        dispatch(setLoadingState(false));

        if(response && response.data){
          dispatch(fetchAllRidersSuccess(response.data.riders));
        }

        if (typeof cb === 'function') {
          cb(response.data);
        }

        })
        .catch((error) => {
        console.log('error', JSON.stringify(error));
        dispatch(setLoadingState(false));
        if (typeof cb === 'function') {
            cb({status : 401});
        }
        });
     }
    };
  };

  export const orderAssignment = (orderId, riderId, status, cb) => {
    return async (dispatch) => {
      var authToken = await getAuthToken();
      if(authToken){
        dispatch(setLoadingState(true));
        var url = basePath + '/api/v1/order/assignment?ordId=' + orderId + '&riderId=' + riderId + '&status=' + status
        axios
        .put(url, null, { headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ authToken,
          }})
        .then((response) => {
          console.log('orderAssignment success');

          dispatch(setLoadingState(false));
          
          if(response){
            var resultObj = {order : {}, riders : []}
            dispatch(fetchRiderListSuccess(resultObj));
          }

          if (typeof cb === 'function') {
            cb(response.data);
          }

        })
        .catch((error) => {
          console.log('error', JSON.stringify(error));
          dispatch(setLoadingState(false));
          if (typeof cb === 'function') {
              cb({status : 401});
          }
        });
     }
    };
  };

  export const addRider = (reqObj, cb) => {
    return async (dispatch) => {
      var authToken = await getAuthToken();
      if(authToken){
        dispatch(setLoadingState(true));
        var url = basePath + '/api/v1/signUp?org=' + orgCode;
        axios
        .post(url, reqObj, { headers: {
            'Content-Type': 'application/json',
          }})
        .then((response) => {
          console.log('add rider success');
          dispatch(setLoadingState(false));

          if (typeof cb === 'function') {
            cb(response.data);
          }
          
        })
        .catch((error) => {
          console.log('error', JSON.stringify(error));
          dispatch(setLoadingState(false));
          if (typeof cb === 'function') {
              cb({status : 401});
          }
        });
     }
    };
  };

  export const resetRiderPass = (reqObj, cb) => {
    return async (dispatch) => {
      var authToken = await getAuthToken();
      if(authToken){
        dispatch(setLoadingState(true));
        var url = basePath + '/api/v1/resetPassword?org=' + orgCode;
        axios
        .post(url, reqObj, { headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ authToken
          }})
        .then((response) => {
          console.log('reset rider password success');
          dispatch(setLoadingState(false));

          if (typeof cb === 'function') {
            cb(response.data);
          }
          
        })
        .catch((error) => {
          console.log('error', JSON.stringify(error));
          dispatch(setLoadingState(false));
          if (typeof cb === 'function') {
              cb({status : 401});
          }
        });
     }
    };
  };

  export const downloadDeliveryReport = (reqObj) => {
    return async (dispatch) => {
     var authToken = await getAuthToken();
     if(authToken){
      dispatch(setLoadingState(true));
      var url = basePath + '/api/v1/download/ordDelSummary?deliveryboy=' 
      + reqObj.riderId + '&startdate=' + reqObj.startDate + '&enddate=' + reqObj.endDate + '&authToken=' + authToken;

      window.open(url);

      dispatch(setLoadingState(false));
     }
    };
  };
  
  export const downloadCODSummaryReport = (reqObj) => {
    return async (dispatch) => {
     var authToken = await getAuthToken();
     if(authToken){
      dispatch(setLoadingState(true));
      var url = basePath + '/api/v1/download/CODSummary?deliveryboy=' 
      + reqObj.riderId + '&startdate=' + reqObj.startDate + '&enddate=' + reqObj.endDate + '&authToken=' + authToken;

      window.open(url);

      dispatch(setLoadingState(false));
     }
    };
  };

  export const downloadOrdSummaryReport = (reqObj) => {
    return async (dispatch) => {
     var authToken = await getAuthToken();
     if(authToken){
      dispatch(setLoadingState(true));
      var url = basePath + '/api/v1/download/ordSummary?';

      if(reqObj.riderId){
        url = url + 'deliveryboy=' + reqObj.riderId + '&';
      }

      url = url + 'startdate=' + reqObj.startDate + '&enddate=' + reqObj.endDate + '&authToken=' + authToken;
      window.open(url);

      dispatch(setLoadingState(false));
     }
    };
  };


  export const getTrackList = (riderId, cb) => {
    return async (dispatch) => {
      var authToken = await getAuthToken();
      if(authToken){
        dispatch(setLoadingState(true));
        var url = basePath + '/api/v1/rider/trackList?riderId=' + riderId;
        axios
        .get(url, { headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ authToken,
          }})
        .then((response) => {
        console.log('fetchRiderTrackList success');

        dispatch(setLoadingState(false));

        if (typeof cb === 'function') {
          cb(response.data);
        }

        })
        .catch((error) => {
        console.log('error', JSON.stringify(error));
        dispatch(setLoadingState(false));
        if (typeof cb === 'function') {
            cb({status : 401});
        }
        });
     }
    };
  };
  
  export const fetchRiderListSuccess = (val) => {
    return {type: FETCH_RIDERS, payload: val };
  };

  export const fetchAllRidersSuccess = (val) => {
    return {type: FETCH_ALL_RIDERS, payload: val };
  };

  
