import { Platform } from "react-native"

import active from "./active.env"

const envs = {
  stage: {
    DEV_API: "https://sradha.differentech.in",
    //DEV_API: "http://localhost:6082",
    SOUNDS: Platform.OS === "android" || false,
    ORG_CODE: "radha_udp"
  },
  prod: {
    DEV_API: "https://sradha.differentech.in",
    //DEV_API: "http://localhost:6082",
    SOUNDS: Platform.OS === "android" || false,
    ORG_CODE: "radha_udp"
  },
  dev: {
    DEV_API: "https://sradha.differentech.in",
    //DEV_API: "http://localhost:6082",
    SOUNDS: Platform.OS === "android" || false,
    ORG_CODE: "radha_udp"
  },
}

export default envs[active]
