export const MONTH_NAMES = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const ROLE_SUPERVISOR = 'SP';

export const ROLE_LIST = [{'name' : 'Rider', 'value' : 'RD'}];

export const ORDER_LIST_TYPES = [{'name' : 'All', 'value' : 'all'}, 
{'name' : 'Assigned', 'value' : 'ASN'}, {'name' : 'Non-Assigned', 'value' : 'OPN'}];

export const DELIVERY_SUMMERY_TYPE = "DELIVERY_SUMMERY_TYPE";
export const COD_SUMMERY_TYPE = "COD_SUMMERY_TYPE";
export const ORD_SUMMERY_TYPE = "ORD_SUMMERY_TYPE";
