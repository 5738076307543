import React from "react";
import { connect } from 'react-redux';
import { View, Text, StyleSheet, TouchableOpacity, ScrollView, Linking } from "react-native";
import { orderAssignment, resetRiderPass } from '../reduxStore/actions/rider/rider';
import { Table, TableWrapper, Row, Rows, Col, Cols, Cell } from 'react-native-table-component';
import { getFormattedDate, getDistanceCalc } from './../helper/index';
import { clearStoreData } from './../storageManager/index';

class RiderItem extends React.Component {

  constructor() {
    super();
    this.state = {
      tableHeadRLFO: ['Name', 'Mobile No.', 'Free From', 'Distance(in km)', 'Action'],
      tableHeadRL: ['Name', 'Mobile No.', 'Free From', 'Action'],
      flexArrRLFO: [2, 2, 1, 1, 1],
      flexArrRL: [3, 3, 1, 4]
    };
  }

  checkIfSelected(riderId) {
    if (this.props.selectedOrder) {
      if (this.props.selectedOrder.riderId != null && this.props.selectedOrder.riderId == riderId) {
        return true;
      }
    }
    return false;
  }


  // getRiderByIndex(riderList, index){
  //   var riderVal = '';
  //   riderList.map((rider, i) => {
  //     if(i == index){
  //       riderVal = rider;
  //     }
  //   });

  //   return riderVal;
  // }

  assignOrder(riderId) {
    const assignStatus = 'ASN';
    this.props.orderAssignment(this.props.selectedOrder.ordId, riderId, assignStatus, response => {
      var message = '';
      if (response.successMessages) {
        message = response.successMessages[0];
      }

      if (response.errorMessages) {
        message = response.errorMessages[0];
      }

      if (message) {
        alert(message);
      }

      if (response.status == 401) {
        this.props.navigation.navigate('Login');
        clearStoreData();
      }
    });
  }

  formGoogleMapLink(rData) {
    var riderData = JSON.parse(rData);
    if (riderData.latitude && riderData.longitude) {
      var url = "https://www.google.com/maps/dir/?api=1&destination=" + riderData.latitude + "," + riderData.longitude;

      Linking.canOpenURL(url).then(supported => {
        if (supported) {
          console.log("Supported url");
          return Linking.openURL(url);
        } else {
          console.log("Unsupported url");
          browser_url =
            "https://www.google.de/maps/@" +
            riderData.latitude +
            "," +
            riderData.longitude;
          return Linking.openURL(browser_url);
        }
      });
    }
  }

  riderTracking(rData) {
    var riderData = JSON.parse(rData);
    var riderId = riderData.riderId;
    //var url = `TrackMap/${riderId}`;
    this.props.navigation.navigate('TrackMap', riderId);
  }

  resetPass(rData) {
    var riderData = JSON.parse(rData);
    if (riderData && riderData.mobileNo) {
      var reqObj = { mobileNo: riderData.mobileNo };
      this.props.resetRiderPass(reqObj, response => {
        var message = '';
        if (response.successMessages) {
          message = response.successMessages[0];
        }

        if (response.errorMessages) {
          message = response.errorMessages[0];
        }

        if (message) {
          alert(message);
        }

        if (response.status == 401) {
          this.props.navigation.navigate('Login');
          clearStoreData();
        }
      });
    }
  }

  render() {
    const { riders } = this.props;
    const state = this.state;
    const tableData = [];
    for (let i = 0; i < riders.length; i += 1) {
      const rowData = [];
      rowData.push(`${riders[i].name}`);
      rowData.push(`${riders[i].mobileNo}`);

      var freeFromVal = '';

      if (riders[i].freeFrom) {
        freeFromVal = getFormattedDate(riders[i].freeFrom);
      }
      rowData.push(`${freeFromVal}`);

      if (this.props.listForOrder) {
        var distance = getDistanceCalc(riders[i].latitude, riders[i].longitude, this.props.selectedOrder.frmLat, this.props.selectedOrder.frmLong, 'K');
        rowData.push(`${distance}`);
        rowData.push(`${riders[i].riderId}`);
      } else {
        var riderData = JSON.stringify(riders[i]);
        rowData.push(`${riderData}`);
      }

      tableData.push(rowData);
    }

    const assignOrderEle = (riderId) => (
      <TouchableOpacity onPress={() => this.assignOrder(riderId)} style={{ alignItems: 'center', justifyContent: 'center' }}>
        <View style={styles.btnContainer}>
          <Text style={styles.btnText}>Assign</Text>
        </View>
      </TouchableOpacity>
    );

    const action = (data) => (
      <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>

        <Text size={15} bold style={styles.linkText} onPress={() => {
          this.formGoogleMapLink(data)
        }}>View Current Location</Text>
        <Text size={15} bold style={styles.linkText} onPress={() => {
          this.riderTracking(data)
        }}>Visited Locations</Text>
        {/* <a href={this.formGoogleMapLink(data)} target="_blank">View Current Location</a> */}
        {/* <a href={this.riderTracking(data)} target="_blank">Visited Locations</a> */}

        <TouchableOpacity onPress={(event) => this.resetPass(data)} style={{ alignItems: 'center', justifyContent: 'center' }}>
          <View style={[styles.btnContainer, { width: '120%' }]}>
            <Text style={styles.btnText}>Reset Password</Text>
          </View>
        </TouchableOpacity>
        {/* <a onClick={(event) => this.resetPass(data)}>Reset Password</a> */}
      </View>

      // <Text style={{color: 'blue'}}
      //       onPress={() => Linking.openURL(this.formGoogleMapLink(longLatValue))}>
      //  View Location
      // </Text>
    );

    return (
      <View style={styles.parentView}>
        <ScrollView horizontal={true}
          contentContainerStyle={{ flex: 1, justifyContent: 'space-between', backgroundColor: '#00E6E1' }}>

          <View style={{ width: '100%' }}>
            <Table>
              <Row data={this.props.listForOrder ? state.tableHeadRLFO : state.tableHeadRL}
                flexArr={this.props.listForOrder ? state.flexArrRLFO : state.flexArrRL}
                style={styles.head} textStyle={styles.headerText} />
              {
                tableData.map((rowData, index) => (
                  <TableWrapper key={index} style={styles.row} style={this.checkIfSelected(rowData[4]) ? [styles.row, styles.rowActive] : [styles.row, styles.rowInActive]} >
                    {
                      rowData.map((cellData, cellIndex) => (
                        <Cell key={cellIndex}
                          data={
                            this.props.listForOrder ? cellIndex === 4 ? assignOrderEle(cellData) : cellData
                              : cellIndex === 3 ? action(cellData) : cellData}
                          textStyle={this.props.listForOrder ? styles.cellTxtRLFO : styles.cellTxtRL} />
                      ))
                    }
                  </TableWrapper>
                ))
              }
            </Table>
          </View>
        </ScrollView>
      </View>
    );
  }
};

const styles = StyleSheet.create({
  linkText: {
    color: 'blue',
    flex: 1,
    lineHeight: 20,
    alignItems: 'center',
    fontSize: 15,
    flexWrap: 'wrap',
    justifyContent: 'center',
    cursor:'pointer'
  },
  parentView: {
    display: 'flex',
    flexDirection: 'column',
    flex: 3
  },
  rowInActive: { backgroundColor: '#E7E6E1' },
  rowActive: { backgroundColor: '#1ed8c0' },
  head: { height: 40, backgroundColor: '#27b45c' },
  headerText: { textAlign: 'center', fontWeight: '500', fontSize: 15, color: '#fff' },
  cellTxtRLFO: { textAlign: 'center', fontWeight: '300', fontSize: 12 },
  cellTxtRL: { textAlign: 'center', fontWeight: '300', fontSize: 13 },
  row: { flexDirection: 'row', backgroundColor: '#E7E6E1', justifyContent: "space-between", padding: 3, width: "100%" },
  btnContainer: { width: 70, height: 30, backgroundColor: '#27b45c', borderRadius: 5, alignItems: "center", justifyContent: "center" },
  btnText: { textAlign: 'center', color: '#fff' }
});

const mapStateToProps = (state) => ({
  selectedOrder: state.rider.selectedOrder,
  // riders: state.rider.riders
});

const mapDispatchToProps = dispatch => {
  return {
    orderAssignment: (orderId, riderId, status, cb) => dispatch(orderAssignment(orderId, riderId, status, cb)),
    fetchRiderListSuccess: (resultObj) => dispatch(fetchRiderListSuccess(resultObj)),
    resetRiderPass: (reqObj, cb) => dispatch(resetRiderPass(reqObj, cb))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RiderItem);
