import React from 'react';
import {
  AppRegistry,
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  Image
} from 'react-native';
import ScreenContainer from './src/navigation/ScreenContainer';
import {Provider} from 'react-redux';
import {store, persistor} from './src/reduxStore/store/store';


const image = require('./assets/menu.png');
export default class App extends React.Component {
   
render() {

  return (
    <Provider store={store}>
      <View style={styles.appContainer}>
          <ScreenContainer /> 
      </View>
      </Provider>
  );
}
}
const styles = StyleSheet.create({
  appContainer: {
    flex: 1,
  },
});

AppRegistry.registerComponent('App', () => App);
