import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { fetchOrder } from '../order/order';
import {getAuthToken, getFromFilter} from './../../../storageManager/index';
import env from "../../../environment/env.js";
const basePath = env.DEV_API;
var sock = null;
export var stompClient = null;

export const stompRegister = () => {
    return async(dispatch) => {
        let url = basePath + "/wsapp/shara/register";
        sock = new SockJS(url);
        stompClient = Stomp.over(sock);
        stompConnect(dispatch);
    }
}
   
export const stompConnect = async(dispatch) => {
    if(stompClient != null){
        var authToken = await getAuthToken();
        stompClient.connect({"X-Authorization": "Bearer " + authToken}, ( frame ) => {
            console.log( "Connected :- "+frame );
            stompSubscription(dispatch);
        });
    }
}

export const stompSubscription = async(dispatch) => {
    if(stompClient != null){
        var authToken = await getAuthToken();
        var fromFilterVal = await getFromFilter();
        stompClient.subscribe("/topic/deliveryRequest", ( res ) => {
            console.log( "Subscriber :- "+  res);
            var resObj = JSON.parse(res.body);
            if(resObj.payload && resObj.payload.fromExtId && resObj.payload.fromExtId == fromFilterVal){
                dispatch(fetchOrder(resObj.payload));
            }
        }, {"X-Authorization": "Bearer " + authToken});
    }
}

export const stompDisconnect = () => {
    if(stompClient != null){
        stompClient.disconnect();
    }
}


