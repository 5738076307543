import React from "react";
import { connect } from 'react-redux';

import { TouchableOpacity, Text, StyleSheet } from "react-native";
import { MapContainer, Marker, Popup, TileLayer, Polyline, Circle } from 'react-leaflet';
//import { Icon } from 'leaflet';
//import L from 'leaflet';
import "./../app.css";  //this is also needed, this has css for leaflet-container class
import 'leaflet/dist/leaflet.css'; //this is very important to display map correctly
import { getTrackList } from '../reduxStore/actions/rider/rider';
import { datePrototype } from './../helper/index';
import { Icon } from 'react-native-elements'

class TrackMap extends React.Component {

  constructor() {
    super();
    this.state = {
      riderTrackingData: [],
      latlongData: []
    }
  }

  componentDidMount() {
    console.log("TrackMap");
    this.setState({ riderTrackingData: [] });
    this.setState({ latlongData: [] });
    this.getSelectedRiderTrackList()
  }

  getSelectedRiderTrackList() {
    //let riderId = this.props.navigation.getParam('riderId');
    let riderId = this.props.navigation.state.params;
    this.props.getTrackList(riderId, response => {
      var message = '';
      if (response.successMessages) {
        // message = response.successMessages[0];

        this.setState({ riderTrackingData: response.riderTrackList });

        var polylineData = [];
        this.state.riderTrackingData.map((val, ind) => {
          var latlongVals = [];
          latlongVals.push(val.latitude);
          latlongVals.push(val.longitude);

          polylineData.push(latlongVals);
        }
        );

        this.setState({ latlongData: polylineData });
      }

      if (response.errorMessages) {
        message = response.errorMessages[0];
      }

      if (message) {
        alert(message);
      }

      if (response.status == 401) {
        this.props.navigation.navigate('Login');
      }
    });
  }

  getVisitedDate(dateInMiliSec) {
    var dateVal = new Date(dateInMiliSec);
    return datePrototype(dateVal, 'dd-mm-yyyy hh:mm');
  }

  goBack(){
    console.log("goback");
    this.props.navigation.goBack();
  }

  render() {
    const center = [13.391002, 74.800079]

    const polyline = [
      [13.391002, 74.800079],
      [13.401, 74.9],
      [13.20, 74.9]
    ]

    // const multiPolyline = [
    //   [
    //     [51.5, -0.1],
    //     [51.5, -0.12],
    //     [51.52, -0.12],
    //   ],
    //   [
    //     [51.5, -0.05],
    //     [51.5, -0.06],
    //     [51.52, -0.06],
    //   ],
    // ]
    const fillBlueOptions = { fillColor: 'blue' }
    const greenOptions = { color: '#27b45c' }
    const redOptions = { color: 'red' }

    return (
      <>
        {this.state.latlongData && this.state.latlongData.length > 0 ?
          <>
            <MapContainer center={this.state.latlongData[0]} zoom={12}>
              <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyrigh">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              />

              <Polyline pathOptions={greenOptions} positions={this.state.latlongData} />

              {this.state.riderTrackingData.map((val, ind) =>
                <Circle key={val.id} center={[val.latitude, val.longitude]} pathOptions={fillBlueOptions} radius={20} >
                  <Popup key={val.id} position={[val.latitude, val.longitude]}>
                    <div><h2>Visited on {this.getVisitedDate(val.itime)}</h2></div>
                  </Popup>
                </Circle>
              )}

              {/* {this.state.riderTrackingData.map((val, ind) =>
                <>
                  <Marker key = {val.id} position={[val.latitude, val.longitude]}>
                  </Marker>

                  <Popup position={[val.latitude, val.longitude]}>
                      <div><h2>A pretty CSS3 popup. <br /> Easily customizable.</h2></div>
                  </Popup>
                </>
              )} */}

              {/* <Circle key = {val.id} center={[val.latitude, val.longitude]} radius={200} >
                  <Popup  key = {val.id} position={[val.latitude, val.longitude]}>
                        <div><h2>{val.latitude}  <br /> {val.longitude}</h2></div>
                  </Popup>
            </Circle> */}


            </MapContainer>
            <TouchableOpacity style={styles.backButton} 
            onPress={() => {this.goBack()}}>
              <Icon name="arrow-left" size={25} color="#fff" type="entypo" />
            </TouchableOpacity>
          </>
          : <></>
        }
      </>
      //   <MapContainer center={center} zoom={13} scrollWheelZoom={false}>
      //   <TileLayer
      //     attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      //     url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      //   />
      //   <Polyline pathOptions={limeOptions} positions={polyline} />
      //   {/* <Polyline pathOptions={limeOptions} positions={multiPolyline} /> */}
      // </MapContainer>
    );
  }
}

const styles = StyleSheet.create({
  backButton: {
    display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly',
    position: 'fixed',
    top: '85%',
    left: '95%',
    height: 50,
    width: 50,
    borderRadius: '50%',
    zIndex: 1999,
    backgroundColor: '#27b45c',
    textAlign: 'center',
    padding: 10,
    fontSize: '1.5em',
    boxShadow: '1px 1px 6px 6px #ccc'
  }
});

const mapDispatchToProps = dispatch => {
  return {
    getTrackList: (riderId, cb) => dispatch(getTrackList(riderId, cb))
  }
}

export default connect(
  null,
  mapDispatchToProps
)(TrackMap);