import React, { Component } from "react";
import { StyleSheet, View, Text, TextInput, ActivityIndicator, Dimensions, TouchableOpacity} from "react-native";
import { connect } from 'react-redux';
import { userLogin } from '../reduxStore/actions/user/user';
import {ROLE_SUPERVISOR} from '../constants/shared';

let {height, width} = Dimensions.get('window');

class Login extends Component {

    constructor(props) {
      super(props);
      this.state = ({
        mobileNo: '',
        password: ''
      })
    }
    
    setField = (field, value) => {
      if (field === 'mobileNo') {
        this.setState({mobileNo: value});
      }
      if (field === 'password') {
        this.setState({password: value});
        this.password.focus();
      }
    }

    toLogIn = () =>{
      const {mobileNo, password } =this.state;
      const { navigation } = this.props;

      let errorMessage = '';
      if(mobileNo === ''){
        errorMessage = errorMessage+'Please enter mobile number';
      }

      if (password === '') {
        if(errorMessage !== ''){
          errorMessage = errorMessage+' and password';
        } else {
          errorMessage = errorMessage+'Please enter password';
        }
        this.clearInputs();
      }

      if(errorMessage !== ''){
        alert(errorMessage);
        return;
      }

      var userData = {mobileNo : mobileNo, password : '' + password};
      this.props.userLogin(userData, response => {
        this.clearInputs();
        if (response.status === 200 && response.data.user.role == ROLE_SUPERVISOR) {
          navigation.navigate('SideMenus');
        } else {
          alert('Please enter valid username and password.');
        }
      
      });
    }

    clearInputs(){
      this.setState({mobileNo: ""});
      this.setState({password: ""});
      this.mobileNo.clear();
      this.password.clear();
    }

    // async componentWillMount() {
    //   const token = await AsyncStorage.getItem('AuthToken');
    //   const mainPage = !!token ? 'Home' : 'Login';
    //   if(this.props.navigation){
    //     this.props.navigation.navigate(mainPage);
    //   }
    // }

    componentDidMount() {
      this.clearInputs();
    }

    render() {
      return (
        <View style={styles.container}>
             {this.props.spinner ? (
          <ActivityIndicator
            style={[styles.centering]}
            color="#ff8179"
            size="large"
          />
        ) : (
          <>
          <View style = {{ width: '100%',  height: '100%'}}>
              <View style = {styles.signInBodyContainer}>
                <View style={styles.signInFields}>
                  <TextInput
                              style={styles.inputText}
                              placeholder="Mobile No."
                              keyboardType = 'numeric'
                              maxLength={12}
                              ref={mobileNoInput => { this.mobileNo = mobileNoInput }}
                              onChangeText={text => this.setField('mobileNo', text)}
                              placeholderTextColor="#000000"
                  />
                  <TextInput
                          secureTextEntry
                          style={styles.inputText}
                          placeholder="Password"
                          keyboardType = 'numeric'
                          maxLength={30}
                          //minLength={6}
                          ref={emailPass => { this.password = emailPass }}
                          onChangeText={text => this.setField('password', text)}
                          placeholderTextColor="#000000"
                  /> 
                </View>
              </View>
              <View style={styles.footerBlock}>
                <TouchableOpacity style={styles.containerButton} onPress={() => this.toLogIn()}>
                  <Text style={styles.caption}>Login In</Text>
                </TouchableOpacity>
              </View>
            </View>
            </>
          )}
        </View>
      );
    }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection : 'row'
  },
  signInFields:{
    flex: 3,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    width: '100%'
  },
  signInBodyContainer : {
    marginTop: 200,
    width: '100%',
    height: '20%', 
    alignItems: 'center',
    justifyContent: 'center'
  },
  footerBlock: {
    width: '100%',
    height: "20%",
    alignItems: 'center',
    justifyContent: 'center'
  },
  inputText: {
    height: 50,
    backgroundColor: '#eeeeee',
    width: '30%',
    borderRadius: 25,
    marginBottom: 10,
    paddingLeft: 15,
    alignItems: "center",
    justifyContent: "center",
  },
  containerButton: {
    width: '20%',
    height: 40,
    backgroundColor: "#27b45c",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 30
  },
  caption: {
    color: "#fff",
    fontSize: 17,
    fontFamily: "roboto-500"
  }
});

const mapStateToProps = (state) => ({
  spinner : state.shared.loading
});

const mapDispatchToProps = dispatch => {
    return {
      userLogin: (userData, cb) => dispatch(userLogin(userData,cb))
    }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login);
