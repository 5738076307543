import React from "react";
import { View, Text, TouchableOpacity, StyleSheet, ScrollView, Image, Alert, Button } from "react-native";
import { connect } from 'react-redux';
import { fetchRiderList} from '../reduxStore/actions/rider/rider';
import { orderDetails, fetchOrderListSuccess } from '../reduxStore/actions/order/order';
import { Table, TableWrapper, Row, Rows, Col, Cols, Cell } from 'react-native-table-component';
import {getFormattedDate, getOrderStatus} from './../helper/index';
import { Icon } from 'react-native-elements'
// import {
//   Tooltip,
// } from 'react-tippy';
import {clearStoreData} from './../storageManager/index';

class OrderItem extends React.Component {

  constructor() {
    super();
    this.state = {
      tableHead: ['Order', 'From', 'To', 'Ordered Time', 'Status', 'Payment Type', 'Dist From Store', 'Action'],
      flexArr: [1, 3, 4, 2, 2, 2, 2, 2]
    };
  }

  getOrderByIndex(orderList, index){
    var orderVal = '';
    orderList.map((order, i) => {
      if(i == index){
        orderVal = order;
      }
    });

    return orderVal;
  }

  /*Each Admin screen will have unique ord code */
  getOrderByOrdCode(orderList, ordCode){
    var orderVal = '';
    orderList.map((order, i) => {
      if(order.ordCode == ordCode){
        orderVal = order;
      }
    });

    return orderVal;
  }


  async getRiders(index) {
    var order = this.getOrderByIndex(this.props.orders, index);
   // if(order.status == 'OPN'){
      var selectedOrder = order
      this.props.fetchRiderList(order,selectedOrder, response => {
        var message = '';
        // if(response.successMessages && (response.riders && response.riders.length == 0)){
        //     message = response.successMessages[0];
        // }

        if(response.successMessages && response.successMessages.length != 0){
          message = response.successMessages[0];
        }

        if(response.riders && response.riders.length != 0){
          message = '';
        }
  
        if(message){
          alert(message);
        }
  
        if(response.status == 401){
          this.props.navigation.navigate('Login');
          clearStoreData();
        }
      });
    //}
    
  };

  getFromAddressTooltip(orderIndex, cellDataIndex){
  
    if(cellDataIndex == 1){
      var order = this.getOrderByIndex(this.props.orders, orderIndex);
  
      var fromAddressDet = `${order.frmAddress} ${order.frmPost} ${order.frmPin}` 
      return fromAddressDet;
    } else {
      return ""
    }
    
  }

  checkIfSelected(ordCode){
    if(this.props.selectedOrder){
      if(this.props.selectedOrder.ordCode != null && this.props.selectedOrder.ordCode == ordCode){
        return true;
      }
    }
    
    return false;
  }

  checkIfFastDelivery(ordCode){
    var ord = this.getOrderByOrdCode(this.props.orders, ordCode);
    if(ord.fastDel == "Y"){
      return true;
    }
    
    return false;
  }

  orderDetailsUpdate(ordDetails){
    this.props.orderDetails(ordDetails,response => {
      console.log("response",response.orderStatusInfo);
      var message = '';
      if (response.successMessages) {
        message = response.successMessages[0];

        var orderList = this.props.orders;
        var orderIndex = orderList.findIndex(item => item.ordId === ordDetails.orderId);
        orderList.splice(orderIndex, 1);
        this.props.fetchOrderListSuccess(null);
        this.props.fetchOrderListSuccess(orderList);
      }

      if (response.errorMessages) {
        message = response.errorMessages[0];
      }

      if (message) {
        alert(message);
      }

      if (response.status == 401) {
        this.props.navigation.navigate('Login');
        clearStoreData();
      }
   });
  }


  confirmCancelOrd(ordCode, status){
    var ord = this.getOrderByOrdCode(this.props.orders, ordCode);
    let ordDetails = {  
      "status": status,
      "orderId" : ord.ordId,
      "ordCode":ord.ordCode,
      "encryptedOrdId":ord.encryptedOrdId,
      "deliveryReqId": ord.id //Added 20210617, required to store deliveryrequest id in orderactivity for future use
    }

    this.orderDetailsUpdate(ordDetails);
  }

  cancelOrder(ordCode, status){

    if (!confirm("Are you sure you want to cancel ?" + "\n" + "This is cannot be undone.")) {
			return;
		}

    this.confirmCancelOrd(ordCode, status);
  }

  render() {
    const { orders} = this.props;
    const state = this.state;
    const tableData = [];
    
    if(orders && orders.length != 0){
      for (let i = 0; i < orders.length; i += 1) {
        const rowData = [];
        rowData.push(`${orders[i].ordCode}`);
        rowData.push(`${orders[i].frmName}`);
        var postPinArea = "";
        postPinArea = orders[i].toPost ? `${orders[i].toPost}` : postPinArea;
        postPinArea = orders[i].toArea ? postPinArea + " " + `${orders[i].toArea}` : postPinArea;
        postPinArea = orders[i].toPin ? postPinArea + " " + `${orders[i].toPin}` : postPinArea;
        rowData.push(`${orders[i].toAddress} ${postPinArea}`);

        var orderedDate = '';
        if(orders[i].ordItime){
          orderedDate = getFormattedDate(orders[i].ordItime);
        }
        rowData.push(`${orderedDate}`);
        
        rowData.push(`${getOrderStatus(orders[i].status)}`);

        rowData.push(`${orders[i].collectionType ? orders[i].collectionType : ""}`);

        rowData.push(`${orders[i].googleDistFromStore != 0 ? orders[i].googleDistFromStore : ""}`);

        rowData.push(`${orders[i].ordCode}`);
        
        tableData.push(rowData);
      }

    }
    
    const element = (cellData, orderIndex, cellDataIndex, isFastDel) => (
      <View style = {styles.cellTxtContainer}>
        <Text style={styles.cellTxt}>{cellData}
        {cellDataIndex == 1 && isFastDel?
         <Image source = {require('../../assets/fastDelivery.png')} 
            fadeDuration={0} style={styles.image} />
           : <></>
        } 
        </Text>
      </View>
      /*Removed Tooltip logic because it has created UI related issues(creating white space after this tooltip display) */
      // <Tooltip
      //   // options
      //   title={this.getFromAddressTooltip(orderIndex, cellDataIndex)}
      //   position="bottom"
      //   trigger="mouseenter"
      // >
      //   <View style = {styles.cellTxtContainer}>
      //     <Text style={styles.cellTxt}>{cellData}</Text>
      //   </View>
      // </Tooltip>
  );

  const action = (data) => (
    <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly' }}>
       {/* <Button title="Simple Alert" onPress={this.cancelOrder(data, "C")} /> */}
      <TouchableOpacity onPress={(event) => this.cancelOrder(data, "C")} style={{ alignItems: 'center', justifyContent: 'center' }}>
        <View style={[styles.btnContainer, { width: '140%' }]}>
          <Text style={styles.btnText}>Cancel</Text>
        </View>
      </TouchableOpacity>
    </View>
  );

  return (
    <View style={styles.parentView}>
        <ScrollView horizontal={true} 
         contentContainerStyle={{flex: 1, justifyContent: 'space-between', backgroundColor: '#00E6E1' }}>
          <View style={{width:'100%'}}>
            <Table >
                <Row data={state.tableHead} flexArr={state.flexArr} style={styles.head} textStyle={styles.headerText}/>
                {
                  tableData.map((rowData, index) => (
                    <TableWrapper key={index} style={styles.row}>
                      {
                          <TouchableOpacity style={this.checkIfSelected(rowData[0]) ? [styles.row, styles.rowActive] : 
                          (this.checkIfFastDelivery(rowData[0]) ? [styles.row, styles.fastDelHighlight] : [styles.row, styles.rowInActive])} onPress={() => this.getRiders(index)}>
                            { rowData.map((cellData, cellIndex) => (
                                <Cell key={cellIndex} data={ cellIndex != 7 ? 
                                  element(cellData, index, cellIndex, this.checkIfFastDelivery(rowData[0]))
                                : action(cellData)}/>
                              )) }
                        </TouchableOpacity>
                      }
                    </TableWrapper>
                  ))
                }
            </Table>
          </View>
        </ScrollView>
      </View>
  );
  }

}

const styles = StyleSheet.create({
  parentView : {
    display : 'flex',
    flexDirection : 'column',
    flex : 3
  },
  rowInActive : {backgroundColor: '#E7E6E1'},
  rowActive : {backgroundColor: '#1ed8c0'},
  head: { height: 40, backgroundColor: '#27b45c' },
  headerText: { textAlign: 'center', fontWeight: '500', fontSize : 15, color : '#fff'},
  row: { flexDirection: 'row', backgroundColor: '#E7E6E1', minHeight: 50, width : "100%"},
  cellTxtContainer : {alignItems: "center", justifyContent: "center"},
  cellTxt : { textAlign: 'center', fontWeight: '300', fontSize : 13 },
  fastDelHighlight : {backgroundColor: '#e36c0a'},
  inputIcons: {
    marginRight: 12
  },
  image:{
    resizeMode: 'contain',
    width : 30,
    height : 25,
   // justifyContent: 'center',
   // alignItems: 'center'
  },
  btnContainer: { width: 70, height: 30, backgroundColor: '#27b45c', borderRadius: 5, alignItems: "center", justifyContent: "center" },
  btnText: { textAlign: 'center', color: '#fff' }
});

const mapStateToProps = (state) => ({
  selectedOrder: state.rider.selectedOrder
});

const mapDispatchToProps = dispatch => {
  return {
    fetchRiderList: (orderData,selectedOrder, cb) => dispatch(fetchRiderList(orderData,selectedOrder, cb)),
    orderDetails: (ordDetails,cb) => dispatch(orderDetails(ordDetails,cb)),
    fetchOrderListSuccess: (orderList) => dispatch(fetchOrderListSuccess(orderList))
  }
}

export default connect(
mapStateToProps,
mapDispatchToProps
)(OrderItem);
