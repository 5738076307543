import React from "react";
//import { NavigationActions } from 'react-navigation';
import {getAuthToken} from './../storageManager/index';
export default class InitScreen extends React.Component {
	
	// componentWillMount() { 
	// 	AsyncStorageData.getAuthToken().then((token) => {
	// 		const mainPage = !!token ? 'Home' : 'Login'; 
	// 		const resetAction = NavigationActions.reset({ index: 0, actions: [
	// 		NavigationActions.navigate({ routeName: mainPage }) ] });
	// 		this.props.navigation.dispatch(resetAction); 
	// 	}); 
	// }
	 
    
    async componentWillMount() {
		const token = await getAuthToken();
		console.log("token" + !!token );
		const mainPage = !!token ? 'SideMenus' : 'Login';
		this.props.navigation.navigate(mainPage);
	}

	render() {
		return (
			<></>
		);
	}

}
