import addDays from 'date-fns/addDays'
import React from "react";
import {StyleSheet, View, Text, TouchableOpacity, ScrollView, TextInput } from 'react-native';
import { connect } from 'react-redux';
import {downloadDeliveryReport, downloadCODSummaryReport, downloadOrdSummaryReport} from '../reduxStore/actions/rider/rider';
import MultiSelect from 'react-native-multiple-select';
import Header from '../components/Header';
import Icon from 'react-native-vector-icons/FontAwesome';
import {getAuthToken, clearStoreData} from './../storageManager/index';
import {getAllRiders} from '../reduxStore/actions/rider/rider';
import {validateDate, datePrototype} from './../helper/index';
import {DELIVERY_SUMMERY_TYPE, COD_SUMMERY_TYPE, ORD_SUMMERY_TYPE} from '../constants/shared';
import DatePicker from 'react-datepicker';
 
import "react-datepicker/dist/react-datepicker.css";

class DeliveryReport extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedRItem : [],
            startDate: new Date(),
            endDate : addDays(new Date(), 30),
            reqStartDate: '',
            reqEndDate: ''
        };
    }

    downloadReport(type){
        if(this.state.selectedRItem.length == 0 && type != ORD_SUMMERY_TYPE){
            alert("Please select rider.");
            return;
        }

        if(!this.state.reqStartDate || !this.state.reqEndDate){
            alert("Please enter valid From and To dates.");
            return;
        }

        // if(!validateDate(this.state.reqStartDate) || !validateDate(this.state.reqEndDate)){
        //     alert("Please enter valid From and To dates.");
        //     return;
        // }

        var riderId = this.state.selectedRItem && this.state.selectedRItem.length > 0 ? this.state.selectedRItem[0] : null;
        var reqObj = {riderId : riderId, startDate : this.state.reqStartDate, endDate : this.state.reqEndDate}

        switch(type){
            case DELIVERY_SUMMERY_TYPE:
                this.props.downloadDeliveryReport(reqObj);
                break;
            case COD_SUMMERY_TYPE:
                this.props.downloadCODSummaryReport(reqObj);
                break;
            case ORD_SUMMERY_TYPE:
                this.props.downloadOrdSummaryReport(reqObj);
                break;
            default:break;
        }
       
    }
    
    onSelectedRItemChange = selectedRItem => {
        this.setState({ selectedRItem });
    };
  
    componentDidMount(){
        this.getAllRiderList();
        this.setState({reqStartDate: datePrototype(this.state.startDate, 'dd/MM/yyyy')});
        this.setState({reqEndDate: datePrototype(this.state.endDate, 'dd/MM/yyyy')});
    }

    setDate(field, dateValue){
        if (field === 'startDate') {
            this.setState({startDate: dateValue});
            this.setState({reqStartDate: datePrototype(dateValue, 'dd/MM/yyyy')});
        }

        if (field === 'endDate') {
            this.setState({endDate: dateValue});
            this.setState({reqEndDate: datePrototype(dateValue, 'dd/MM/yyyy')});
        }
    }

    async getAllRiderList(){
        var authToken = await getAuthToken();
        if(authToken){
            this.props.getAllRiders(response => {
                if(response.status == 401){
                    this.props.navigation.navigate('Login');
                    clearStoreData();
                }
            });
        } else {
            this.props.navigation.navigate('Login');
        }
    }
    
	render() {

		return (
            <>
            <Header title="Delivery Report" />
            {/*<View style = {{justifyContent : 'flex-start', alignItems : 'flex-start'}}>
                 <Link routeName="Home"> Back to Home</Link> 
            </View>*/}
              <ScrollView noSpacer={true} style = {{height: '100%'}}>
            <View style = {{ width: '98%',  height: '100%',  marginLeft : 12, marginTop : 20, borderColor: '#27b45c', borderWidth: 1, borderRadius: 10}}>
                <View style = {styles.parentView}>
                    <View style={styles.selectItemContainer}>
                        <ScrollView style = {{height: 50}}>
                            <MultiSelect
                                    hideTags
                                    items={this.props.riderList}
                                    single={true}
                                    uniqueKey={"riderId" + ""}
                                    ref={(component) => { this.multiSelect = component }}
                                    onSelectedItemsChange={this.onSelectedRItemChange}
                                    selectedItems={this.state.selectedRItem}
                                    selectText="Select Rider"
                                    searchInputPlaceholderText="Search Rider..."
                                    onChangeInput={ (text)=> console.log(text)}
                                    tagRemoveIconColor="#CCC"
                                    tagBorderColor="#CCC"
                                    tagTextColor="#CCC"
                                    selectedItemTextColor="#CCC"
                                    selectedItemIconColor="#CCC"
                                    itemTextColor="#000"
                                    displayKey="name"
                                    searchInputStyle={{ color: '#CCC' }}
                                    submitButtonColor="#CCC"
                                    submitButtonText="Select"
                            />
                        </ScrollView>
                    </View>
                    <View style = {{width : "12%"}}>
                        <View style = {{alignItems: 'center', justifyContent: 'center'}}>
                                <Text>From</Text>
                        </View>
                        <View style={[styles.inputContainer, {width : "136%"}]}>
                            <DatePicker
                                style={styles.inputText}
                                textStyle={{ color: 'green' }}
                                selected={ this.state.startDate }
                                onChange={ date => this.setDate("startDate", date)}
                                name="startDate"
                                dateFormat="dd/MM/yyyy"
                            />
                            {/* <TextInput
                            type = "date"
                                    style={styles.inputText}
                                    placeholder="yyyy-mm-dd"
                                    value = {this.state.startDate}
                                    ref={startDateInput => { this.startDate = startDateInput }}
                                    onChangeText={text => this.setState({'startDate' : text})}
                                    placeholderTextColor="#000000"
                            /> */}
                        </View>
                    </View>
                    <View style = {{width : "12%"}}>
                        <View style = {{alignItems: 'center', justifyContent: 'center'}}>
                                <Text>To</Text>
                        </View>
                        <View style={[styles.inputContainer, {width : "196%"}]}>
                            <DatePicker
                                style={styles.inputText}
                                selected={ this.state.endDate }
                                onChange={ date => this.setDate("endDate", date)}
                                name="endDate"
                                dateFormat="dd/MM/yyyy"
                            />
                            {/* <TextInput
                                    style={styles.inputText}
                                    placeholder="yyyy-mm-dd"
                                    value = {this.state.endDate}
                                    ref={endDateInput => { this.endDate = endDateInput }}
                                    onChangeText={text => this.setState({'endDate' : text})}
                                    placeholderTextColor="#000000"
                            /> */}
                        </View>
                    </View>
                    

                    <View style={[styles.footerBlock, { marginLeft: "10%"}]}>
                        <View style={styles.submitButtonContainer}>
                            <TouchableOpacity style={styles.containerButton} onPress={() => this.downloadReport(DELIVERY_SUMMERY_TYPE)}>
                                <Text style={styles.caption}>
                                <Icon name="download" size={15} color="#ffffff" /> Delivery Summary</Text>
                            </TouchableOpacity>                  
                        </View>
                    </View>

                    <View style={styles.footerBlock}>
                        <View style={styles.submitButtonContainer}>
                                <TouchableOpacity style={styles.containerButton} onPress={() => this.downloadReport(COD_SUMMERY_TYPE)}>
                                    <Text style={styles.caption}>
                                    <Icon name="download" size={15} color="#ffffff" /> COD Summary</Text>
                                </TouchableOpacity>                  
                        </View>
                    </View>

                    <View style={styles.footerBlock}>
                        <View style={styles.submitButtonContainer}>
                                <TouchableOpacity style={styles.containerButton} onPress={() => this.downloadReport(ORD_SUMMERY_TYPE)}>
                                    <Text style={styles.caption}>
                                    <Icon name="download" size={15} color="#ffffff" /> Order Summary</Text>
                                </TouchableOpacity>                  
                        </View>
                    </View>
                </View>
            </View>
            </ScrollView>
            </>
		);
	}

}

var styles = StyleSheet.create({
    parentView : {
        display : 'flex',
        flexDirection : 'row',
        width : '99%',
        height : "100%",
        marginTop: 20,
        marginLeft: 20
    },
    inputContainer :{
        display : 'flex',
        justifyContent: 'center',
        //width: '100%',
        //height: 100,
        alignItems: 'center'
    },
    inputText: {
        // height: 40,
        // backgroundColor: '#eeeeee',
        // width: '80%',
        // borderRadius: 10,
        // marginBottom: 10,
        // padding: 20,
        // alignItems: "center",
        // justifyContent: "center",
    },
    submitButtonContainer: {
        width: '90%',
        height: 50,
        alignItems: 'center',
        justifyContent: 'center'
    },
    containerButton: {
        width: '100%',
        height: 35,
        backgroundColor: "#27b45c",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 5
    },
    caption: {
        fontSize: 15,
        fontFamily: "roboto-500",
        //fontWeight: 'bold',
        fontStyle: 'normal',
        color: '#ffffff'
    },
    selectItemContainer: {
        width: '20%',
        height: '70%',
        backgroundColor: '#FFFFFF',
        borderBottomRightRadius: 15,
        borderBottomLeftRadius: 15,
    },
    footerBlock: {
        width: '15%',
        height: "45%",
        marginTop: 10,
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        display : 'flex',
        flexDirection : 'row'
    },
});

const mapStateToProps = (state) => ({
    riderList : state.rider.allRiders
});
  
const mapDispatchToProps = dispatch => {
    return {
        downloadDeliveryReport: (reqObj) => dispatch(downloadDeliveryReport(reqObj)),
        downloadCODSummaryReport : (reqObj) => dispatch(downloadCODSummaryReport(reqObj)),
        downloadOrdSummaryReport: (reqObj) => dispatch(downloadOrdSummaryReport(reqObj)),
        getAllRiders: (cb) => dispatch(getAllRiders(cb)) 
    }
}
  
export default connect(
mapStateToProps,
mapDispatchToProps
)(DeliveryReport);
  
