import { MONTH_NAMES } from './../constants/shared';
import moment from 'moment';

export const getNearestRider = (riderList) =>{
  let nearestRiders = [];

  /* we need to restrict riders to mangalore / manipal. 
   * For this, when we get list of riders, show only those riders who are within 15 kms */
  for(let i = 0; i < riderList.length; i++){
    if(riderList[i].distance < 15){
      nearestRiders.push(riderList[i]);
    }
  }

  return nearestRiders;
}

export const datePrototype = (dateVal, format) => {
	var yyyy = dateVal.getFullYear().toString();
	var mm = (dateVal.getMonth() + 1).toString(); // getMonth() is zero-based         
	var dd = dateVal.getDate().toString();

  var hours = dateVal.getHours();
  var minutes = dateVal.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var timeVal = hours + ':' + minutes + ' ' + ampm;

  if(format === 'dd-mm-yyyy'){
    return (dd[1] ? dd : "0" + dd[0]) + '-' + MONTH_NAMES[mm - 1] + '-' + yyyy ;
  } else if(format === 'dd/MM/yyyy'){
    return  (dd[1] ? dd : "0" + dd[0]) + '/' + (mm[1] ? mm : "0" + mm[0]) + '/' + yyyy;
  } else if(format === 'dd-mm-yyyy hh:mm'){
    return  (dd[1] ? dd : "0" + dd[0]) + '-' + MONTH_NAMES[mm - 1] + '-' + yyyy + " " + timeVal;
  }
	
};

export const getFormattedDate = (orderDtTime) => {
    var dt = new Date(orderDtTime);
    var now = new Date();
    var diff = (now - dt) / 60000;
  
    if (diff < 60) {
      return Math.round(diff) + " minutes ago";
    } else if (diff < 1440) { // 24*60
      return Math.round(diff / 60) + " hours ago";
    } else if (diff < 2880) { // 24*60*2
      return "1 day " + Math.round((diff - 1440) / 60) + " hours ago ";
    } else if (diff < 4320) { // 24*60
      return "2 days ago ";
    } else {
      return datePrototype(dt, 'dd-mm-yyyy');
    }
}

export const getDistanceCalc = (riderLat, riderLon, destLat, destLon, unit) => {
    if ((riderLat == destLat) && (riderLon == destLon)) {
      return 0;
    }
    else {
      var radlat1 = Math.PI * riderLat/180;
      var radlat2 = Math.PI * destLat/180;
      var theta = riderLon-destLon;
      var radtheta = Math.PI * theta/180;
      var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = dist * 180/Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit=="K") { dist = dist * 1.609344 }
      dist = dist.toFixed(1);
      return dist;
    }
  }

export const getOrderStatus = (status) => {
  switch(status){
    case 'ASN' : 
          return 'Assigned';
    case 'OPN' : 
          return 'Open';
    case 'PUP' : 
          return 'Pick up point';
    case 'OFD' : 
          return 'Out for delivery';
    case 'DLD' : 
          return 'Delivered';
    case 'CLS' : 
          return 'Closed';
    case 'ACP' : 
          return 'Accepted';
    case 'OFF' : 
          return 'Off';
    default : 
          return status;
  }
}

export const validateDate = (dateInput) => {
  var date = moment(dateInput);
 // if(date.isValid() && date._f == 'DD/MM/YYYY'){
  if(date.isValid()){
    return true;
  }
  return false;
}

export const filterOrderist = (orders, filterBy, value) => {
  return orders.filter(l => {
    if(filterBy == 'status'){
      return l.status != null && l.status.toLowerCase().match( value.toLowerCase() );
    } else if(filterBy == 'area'){
      return l.toArea != null && l.toArea.toLowerCase().match( value.toLowerCase() );
    } else if(filterBy == 'pin'){
      return l.toPin != null && l.toPin.match( value );
    }
   });
}

 
